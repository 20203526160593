import * as React from "react";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { FormWrapper } from "../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { RichTextWidget } from "../rich-text-widget";
import UserAndDateWidget from "../user-and-date/UserAndDateWidget.widget";
import { Quotation, QUOTATION_META } from "./table";

export type Data = Quotation;

export const Fields = {
    rejected: UserAndDateWidget,
    rejectedNotes: RichTextWidget,
};

export function actionCancelRejection(state: State, data: Data) {
    return {
        state,
        data: {
            ...data,
            rejected: {
                user: null,
                date: null,
            },
        },
    };
}

function Component(props: Props) {
    return (
        <>
            <FormWrapper label="Change Order Proposal Rejected">
                <widgets.rejected enableReset />
            </FormWrapper>
            <widgets.rejectedNotes />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.rejected> &
    WidgetContext<typeof Fields.rejectedNotes>;
type ExtraProps = {};
type BaseState = {
    rejected: WidgetState<typeof Fields.rejected>;
    rejectedNotes: WidgetState<typeof Fields.rejectedNotes>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "REJECTED"; action: WidgetAction<typeof Fields.rejected> }
    | {
          type: "REJECTED_NOTES";
          action: WidgetAction<typeof Fields.rejectedNotes>;
      }
    | { type: "CANCEL_REJECTION" };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.rejected, data.rejected, cache, "rejected", errors);
    subvalidate(
        Fields.rejectedNotes,
        data.rejectedNotes,
        cache,
        "rejectedNotes",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "REJECTED": {
            const inner = Fields.rejected.reduce(
                state.rejected,
                data.rejected,
                action.action,
                subcontext
            );
            return {
                state: { ...state, rejected: inner.state },
                data: { ...data, rejected: inner.data },
            };
        }
        case "REJECTED_NOTES": {
            const inner = Fields.rejectedNotes.reduce(
                state.rejectedNotes,
                data.rejectedNotes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, rejectedNotes: inner.state },
                data: { ...data, rejectedNotes: inner.data },
            };
        }
        case "CANCEL_REJECTION":
            return actionCancelRejection(state, data);
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    rejected: function (
        props: WidgetExtraProps<typeof Fields.rejected> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "REJECTED",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "rejected", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.rejected.component
                state={context.state.rejected}
                data={context.data.rejected}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Rejected"}
            />
        );
    },
    rejectedNotes: function (
        props: WidgetExtraProps<typeof Fields.rejectedNotes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "REJECTED_NOTES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "rejectedNotes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.rejectedNotes.component
                state={context.state.rejectedNotes}
                data={context.data.rejectedNotes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Rejected Notes"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: QUOTATION_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let rejectedState;
        {
            const inner = Fields.rejected.initialize(
                data.rejected,
                subcontext,
                subparameters.rejected
            );
            rejectedState = inner.state;
            data = { ...data, rejected: inner.data };
        }
        let rejectedNotesState;
        {
            const inner = Fields.rejectedNotes.initialize(
                data.rejectedNotes,
                subcontext,
                subparameters.rejectedNotes
            );
            rejectedNotesState = inner.state;
            data = { ...data, rejectedNotes: inner.data };
        }
        let state = {
            initialParameters: parameters,
            rejected: rejectedState,
            rejectedNotes: rejectedNotesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={QUOTATION_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    rejected: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.rejected>
    >;
    rejectedNotes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.rejectedNotes>
    >;
};
// END MAGIC -- DO NOT EDIT
