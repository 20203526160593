import { RecordMeta } from "../../clay/meta";
import { genUUID, UUID } from "../../clay/uuid";
import { Version } from "../../clay/version";

//!Data
export type ClientCommunicationTemplate = {
    id: UUID;
    recordVersion: Version;
    name: string;
    subject: string;
    body: string;
};

// BEGIN MAGIC -- DO NOT EDIT
export type ClientCommunicationTemplateJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    subject: string;
    body: string;
};

export function JSONToClientCommunicationTemplate(
    json: ClientCommunicationTemplateJSON
): ClientCommunicationTemplate {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        subject: json.subject,
        body: json.body,
    };
}
export type ClientCommunicationTemplateBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    subject?: string;
    body?: string;
};

export function newClientCommunicationTemplate(): ClientCommunicationTemplate {
    return JSONToClientCommunicationTemplate(
        repairClientCommunicationTemplateJSON(undefined)
    );
}
export function repairClientCommunicationTemplateJSON(
    json: ClientCommunicationTemplateBrokenJSON | undefined
): ClientCommunicationTemplateJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            subject: json.subject || "",
            body: json.body || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            subject: undefined || "",
            body: undefined || "",
        };
    }
}

export function ClientCommunicationTemplateToJSON(
    value: ClientCommunicationTemplate
): ClientCommunicationTemplateJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        subject: value.subject,
        body: value.body,
    };
}

export const CLIENT_COMMUNICATION_TEMPLATE_META: RecordMeta<
    ClientCommunicationTemplate,
    ClientCommunicationTemplateJSON,
    ClientCommunicationTemplateBrokenJSON
> & { name: "ClientCommunicationTemplate" } = {
    name: "ClientCommunicationTemplate",
    type: "record",
    repair: repairClientCommunicationTemplateJSON,
    toJSON: ClientCommunicationTemplateToJSON,
    fromJSON: JSONToClientCommunicationTemplate,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        subject: { type: "string" },
        body: { type: "string" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

// END MAGIC -- DO NOT EDIT
