import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { format as formatDate } from "date-fns";
import Decimal from "decimal.js";
import { every } from "lodash";
import * as React from "react";
import {
    Button,
    Dropdown,
    DropdownButton,
    ListGroup,
    ListGroupItem,
    Pagination,
} from "react-bootstrap";
import { fetchRecord, useRecordQuery } from "../../../clay/api";
import { Dictionary } from "../../../clay/common";
import { TAB_STYLE } from "../../../clay/paginated-widget";
import { propCheck } from "../../../clay/propCheck";
import {
    QuickCacheApi,
    useQuickCache,
    useQuickRecord,
} from "../../../clay/quick-cache";
import { UserPermissions } from "../../../clay/server/api";
import { newUUID } from "../../../clay/uuid";
import { useQuickAllRecordsSorted } from "../../../clay/widgets/dropdown-link-widget";
import { Optional } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetData,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { hasPermission } from "../../../permissions";
import { useUser } from "../../state";
import { USER_META } from "../../user/table";
import {
    DATED_EMBEDDED,
    EmbeddedRecordState,
    EmbeddedRecordStateAction,
    EmbeddedRecordStateOptions,
    embededRecordStateReduce,
    initializeEmbeddedRecordState,
    useEmbeddedRecordState,
} from "../embedded-records";
import { Datum, Summary } from "../project-items";
import { Project, PROJECT_META } from "../table";
import {
    CertifiedForemanAssessmentTemplate,
    CERTIFIED_FOREMAN_ASSESSMENT_TEMPLATE_META,
} from "./admin/table";
import {
    CertifiedForemanAssessment,
    CERTIFIED_FOREMAN_ASSESSMENT_META,
} from "./table";
import { CertifiedForemanAssessmentWidget } from "./Widget";

export type Data = Project;

export const Embedded = {
    certifiedForemanAssessment: CertifiedForemanAssessmentWidget,
};

export const Fields = {
    name: Optional(TextWidget),
};

function ShowAssessment(props: { assessment: CertifiedForemanAssessment }) {
    const user = useQuickRecord(USER_META, props.assessment.user);
    const cache = useQuickCache();
    return (
        <Summary
            title="CF Assessment"
            icon={faClipboardList}
            valid={
                CertifiedForemanAssessmentWidget.validate(
                    props.assessment,
                    cache
                ).length == 0
            }
            finalized={props.assessment.date !== null}
        >
            <Datum label="User" value={user} format={(x) => x.name} />
            <Datum
                label="Date"
                value={props.assessment.date}
                format={(x) => formatDate(x, "y-M-d p")}
            />
            <Datum
                label="Last Modified"
                value={props.assessment.modifiedDateTime}
                format={(x) => formatDate(x, "y-M-d p")}
            />
        </Summary>
    );
}

function maybeSingle<T>(items: T[]): T | null {
    if (items.length == 1) {
        return items[0];
    } else {
        return null;
    }
}

export function reduce(
    state: State,
    data: Project,
    action: Action,
    context: Context
): WidgetResult<State, Project> {
    switch (action.type) {
        case "START_CERTIFIED_FOREMAN_ASSESSMENT": {
            const newAssessment: CertifiedForemanAssessment =
                buildCertifiedForemanAssessment(action);
            return {
                data,
                state: {
                    ...state,
                    certifiedForemanAssessment: initializeEmbeddedRecordState(
                        CertifiedForemanAssessmentWidget,
                        newAssessment,
                        context,
                        true
                    ),
                },
            };
        }
        case "RESET":
            return {
                data,
                state: {
                    ...state,
                    certifiedForemanAssessment: null,
                },
            };
        default:
            return baseReduce(state, data, action, context);
    }
}

export type ExtraActions =
    | {
          type: "START_CERTIFIED_FOREMAN_ASSESSMENT";
          user: UserPermissions;
          project: Project;
          template: CertifiedForemanAssessmentTemplate;
          certifiedForemanAssessments: CertifiedForemanAssessment[];
      }
    | {
          type: "RESET";
      };

export function buildCertifiedForemanAssessment(action: {
    user: UserPermissions;
    project: Project;
    template: CertifiedForemanAssessmentTemplate;
    certifiedForemanAssessments: CertifiedForemanAssessment[];
}) {
    const newAssessment: CertifiedForemanAssessment = {
        template: action.template.id.uuid,
        id: newUUID(),
        mobile: false,
        firstDate: null,
        date: null,
        recordVersion: { version: null },
        addedDateTime: null,
        modifiedDateTime: null,
        user: action.user.id,
        project: action.project.id.uuid,
        sections: action.template.surveySections.map((section) => ({
            name: section.name,
            questions: section.questions.map((question) => ({
                id: question.id,
                question: question.question,
                sendToCustomer: question.sendToCustomer,
                controlsSection: question.controlsSection,
                answers: question.answers,
                selectedAnswer: null,
                comment: "",
            })),
        })),
        number: action.certifiedForemanAssessments
            .reduce(
                (current, quotation) => Decimal.max(current, quotation.number),
                new Decimal(0)
            )
            .plus(1),
    };
    return newAssessment;
}

export function usePotentialCertifiedForemanAssessmentTemplates(
    project: Project | undefined | null
) {
    const allTemplates = useQuickAllRecordsSorted(
        CERTIFIED_FOREMAN_ASSESSMENT_TEMPLATE_META,
        (x) => x.name
    );
    if (!allTemplates || !project) {
        return [];
    }
    return allTemplates;
}

function ItemsList() {
    const context = React.useContext(ReactContext)!;

    const user = useUser();

    const certifiedForemanAssessments = useRecordQuery(
        CERTIFIED_FOREMAN_ASSESSMENT_META,
        {
            filters: [
                {
                    column: "project",
                    filter: {
                        equal: context.data.id.uuid,
                    },
                },
            ],
            sorts: ["-date"],
        },
        [],
        hasPermission(user, "CertifiedForemanAssessment", "read")
    );

    const quickCache = useQuickCache();

    const allValid =
        certifiedForemanAssessments &&
        every(
            certifiedForemanAssessments.map(
                (assesment) =>
                    CertifiedForemanAssessmentWidget.validate(
                        assesment,
                        quickCache
                    ).length == 0
            )
        );

    const ceritifiedForemanAsssessmentTemplates =
        usePotentialCertifiedForemanAssessmentTemplates(context.data);

    return (
        <ListGroup>
            <ListGroupItem>
                {ceritifiedForemanAsssessmentTemplates.length == 1 ? (
                    <Button
                        disabled={!allValid}
                        style={{
                            marginLeft: "1em",
                        }}
                        onClick={() => {
                            certifiedForemanAssessments &&
                                context.dispatch({
                                    type: "START_CERTIFIED_FOREMAN_ASSESSMENT",
                                    user,
                                    certifiedForemanAssessments,
                                    project: context.data,
                                    template:
                                        ceritifiedForemanAsssessmentTemplates[0],
                                });
                        }}
                    >
                        New {ceritifiedForemanAsssessmentTemplates[0].name} Site
                        Visit Report
                    </Button>
                ) : (
                    <DropdownButton
                        id="new-site-visit-report"
                        title="New CF Assessment"
                        style={{
                            marginLeft: "1em",
                        }}
                    >
                        {ceritifiedForemanAsssessmentTemplates.map(
                            (template) => (
                                <Dropdown.Item
                                    disabled={!allValid}
                                    onClick={() => {
                                        certifiedForemanAssessments &&
                                            context.dispatch({
                                                type: "START_CERTIFIED_FOREMAN_ASSESSMENT",
                                                user,
                                                project: context.data,
                                                template,
                                                certifiedForemanAssessments,
                                            });
                                    }}
                                >
                                    {template.name}
                                </Dropdown.Item>
                            )
                        )}
                    </DropdownButton>
                )}
            </ListGroupItem>

            {certifiedForemanAssessments &&
                certifiedForemanAssessments.map((assessment) => (
                    <ListGroupItem
                        key={assessment.id.uuid}
                        style={{
                            display: "flex",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                            }}
                        >
                            <ShowAssessment assessment={assessment} />
                        </div>

                        <Button
                            style={{
                                marginLeft: "auto",
                            }}
                            onClick={() =>
                                context.dispatch({
                                    type: "OPEN_CERTIFIED_FOREMAN_ASSESSMENT",
                                    certifiedForemanAssessment: assessment,
                                })
                            }
                        >
                            Open
                        </Button>
                    </ListGroupItem>
                ))}
        </ListGroup>
    );
}
function Component(props: Props) {
    return (
        <>
            <EmbeddedRecords
                certifiedForemanAssessment={{
                    ...DATED_EMBEDDED,
                    generateRequests(data, cache, detail) {
                        return [];
                    },
                }}
                mainTabLabel="Assessments"
            >
                <ItemsList />
            </EmbeddedRecords>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.name> &
    WidgetContext<typeof Embedded.certifiedForemanAssessment>;
type ExtraProps = {};
type BaseState = {
    name: WidgetState<typeof Fields.name>;
    certifiedForemanAssessment: EmbeddedRecordState<
        WidgetData<typeof Embedded.certifiedForemanAssessment>
    >;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "NAME"; action: WidgetAction<typeof Fields.name> }
    | {
          type: "CERTIFIED_FOREMAN_ASSESSMENT";
          action: EmbeddedRecordStateAction<
              WidgetData<typeof Embedded.certifiedForemanAssessment>
          >;
      }
    | {
          type: "OPEN_CERTIFIED_FOREMAN_ASSESSMENT";
          certifiedForemanAssessment: WidgetData<
              typeof Embedded.certifiedForemanAssessment
          >;
          initialParameters?: string[];
      }
    | { type: "RESET" };

export type Action = ExtraActions | BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.name, data.name, cache, "name", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = Fields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "CERTIFIED_FOREMAN_ASSESSMENT": {
            const inner = embededRecordStateReduce(
                Embedded.certifiedForemanAssessment,
                state.certifiedForemanAssessment,
                action.action,
                context
            );
            return {
                state: { ...state, certifiedForemanAssessment: inner },
                data: data,
            };
        }
        case "OPEN_CERTIFIED_FOREMAN_ASSESSMENT": {
            return {
                state: {
                    ...state,
                    certifiedForemanAssessment: initializeEmbeddedRecordState(
                        Embedded.certifiedForemanAssessment,
                        action.certifiedForemanAssessment,
                        context,
                        false,
                        action.initialParameters
                    ),
                },
                data,
            };
        }
        case "RESET": {
            return {
                state: {
                    ...state,
                    certifiedForemanAssessment: null,
                },
                data,
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    name: function (
        props: WidgetExtraProps<typeof Fields.name> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "NAME", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.name.component
                state={context.state.name}
                data={context.data.name}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Name"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let nameState;
        {
            const inner = Fields.name.initialize(
                data.name,
                subcontext,
                subparameters.name
            );
            nameState = inner.state;
            data = { ...data, name: inner.data };
        }
        let certifiedForemanAssessmentState;
        {
            const inner = null;
            certifiedForemanAssessmentState = inner;
            data = data;
        }
        let state = {
            initialParameters: parameters,
            name: nameState,
            certifiedForemanAssessment: certifiedForemanAssessmentState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        React.useEffect(() => {
            if (props.state.initialParameters) {
                switch (props.state.initialParameters[0]) {
                    case "certifiedForemanAssessment":
                        fetchRecord(
                            Embedded.certifiedForemanAssessment.dataMeta,
                            props.state.initialParameters[1]
                        ).then(
                            (record) =>
                                record &&
                                props.dispatch({
                                    type: "OPEN_CERTIFIED_FOREMAN_ASSESSMENT",
                                    certifiedForemanAssessment: record,
                                    initialParameters:
                                        props.state.initialParameters!.slice(2),
                                })
                        );
                        break;
                }
            }
        }, [props.state.initialParameters]);
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: reduce,
    encodeState: (state) => {
        if (state.certifiedForemanAssessment) {
            return [
                "certifiedForemanAssessment",
                state.certifiedForemanAssessment.data.id.uuid,
                ...(Embedded.certifiedForemanAssessment.encodeState?.(
                    state.certifiedForemanAssessment.state
                ) || []),
            ];
        }
        return [];
    },
};
export default Widget;
type Widgets = {
    name: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.name>
    >;
};
function EmbeddedRecords(props: {
    certifiedForemanAssessment: EmbeddedRecordStateOptions<
        WidgetData<typeof Embedded.certifiedForemanAssessment>
    >;
    children: React.ReactNode;
    mainTabLabel: string;
    extraTabWidget?: React.ReactNode;
}) {
    const context = React.useContext(ReactContext)!;
    const certifiedForemanAssessmentDispatch = React.useCallback(
        (
            action: EmbeddedRecordStateAction<
                WidgetData<typeof Embedded.certifiedForemanAssessment>
            >
        ) => {
            context.dispatch({ type: "CERTIFIED_FOREMAN_ASSESSMENT", action });
        },
        [context.dispatch]
    );
    const certifiedForemanAssessment = useEmbeddedRecordState(
        Embedded.certifiedForemanAssessment,

        context.state.certifiedForemanAssessment,
        certifiedForemanAssessmentDispatch,
        context.status,
        props.certifiedForemanAssessment
    );
    return (
        <>
            <div {...TAB_STYLE}>
                {certifiedForemanAssessment.mainComponent || props.children}
            </div>
            <Pagination style={{ marginBottom: "0px" }}>
                <Pagination.Item
                    active={!certifiedForemanAssessment}
                    onClick={() => {
                        context.dispatch({ type: "RESET" });
                    }}
                >
                    {props.mainTabLabel}
                </Pagination.Item>
                {certifiedForemanAssessment.tabs}
                {props.extraTabWidget}
            </Pagination>
        </>
    );
}
// END MAGIC -- DO NOT EDIT
