import * as React from "react";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import UserAndDateWidget from "../user-and-date/UserAndDateWidget.widget";
import { QuotationOption, QUOTATION_OPTION_META } from "./table";
import { formatMoney } from "../estimate/TotalsWidget.widget";

export type Data = QuotationOption;

export const Fields = {
    approved: UserAndDateWidget,
};

export function Component(props: Props) {
    return (
        <tr>
            <td>{props.data.name}</td>
            <td>{props.data.description}</td>
            <td>{formatMoney(props.data.details.total)}</td>
            <td>
                <widgets.approved setLabel="Approve" enableReset />
            </td>
        </tr>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.approved>;
type ExtraProps = {};
type BaseState = {
    approved: WidgetState<typeof Fields.approved>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "APPROVED"; action: WidgetAction<typeof Fields.approved> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.approved, data.approved, cache, "approved", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "APPROVED": {
            const inner = Fields.approved.reduce(
                state.approved,
                data.approved,
                action.action,
                subcontext
            );
            return {
                state: { ...state, approved: inner.state },
                data: { ...data, approved: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    approved: function (
        props: WidgetExtraProps<typeof Fields.approved> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "APPROVED",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "approved", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.approved.component
                state={context.state.approved}
                data={context.data.approved}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Approved"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: QUOTATION_OPTION_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let approvedState;
        {
            const inner = Fields.approved.initialize(
                data.approved,
                subcontext,
                subparameters.approved
            );
            approvedState = inner.state;
            data = { ...data, approved: inner.data };
        }
        let state = {
            initialParameters: parameters,
            approved: approvedState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={QUOTATION_OPTION_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    approved: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.approved>
    >;
};
// END MAGIC -- DO NOT EDIT
